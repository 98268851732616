/*                   START GENERAL                  */
body {
  background-color: #EEEEEE;
  font-family: 'Muli','Roboto', sans-serif;
  text-align: center;
}

.fade {
  -webkit-animation: fade 2s;
  animation: fade 2s;
  -moz-animation: fade 2s;
  -o-animation: fade 2s;
}

.fadeInOut {
  -webkit-animation: fadeInOut 6s;
  animation: fadeInOut 6s;
  -moz-animation: fadeInOut 6s;
  -o-animation: fadeInOut 6s;
}

.sectionHeaderDiv {
  text-align: center;
  padding: 25px 0 25px 0;
}

#loading{
    display:none;
}

#welcome {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: block;
}

.spotifyAuth {
    background-color: #a2bbab;
    width: 50%;
    margin: 0 auto;
    border-radius: 4px;
}

#app {
  display:none;
}

a {
  color: black;
  text-decoration: none; /* no underline */
}

.numberTrackP{
  float: left;
  font-size: 20px;
  margin: 42px 0 0 10px;
}

.topBarTitle{
  display: inline-block;
  padding: 5px;
  width: 40%;
  text-align: left;
}

.topBar {
  display: inline-block;
  padding: 5px;
  width: 13%;
}

.topBar:hover {
  display: inline-block;
  padding: 5px;
  width: 13%;
  background-color: #a2bbab;
}

.topBarSelected {
  display: inline-block;
  padding: 5px;
  background-color: #a2bbab;
  width: 13%;
  height: 100%;
}

.topBarSection {
  background-color: #EEEEEE;
  font-weight: 100;
}

.analysisMessage{
  font-size: 20px;
}

.analysisMessageChart{
  font-size: 26px;
}

@-webkit-keyframes fade {
0% {opacity: 0};
100% {opacity: 1};
}

@-moz-keyframes fade{
  0% {opacity: 0};
  100% {opacity: 1};
}

@keyframes fade {
  0% {opacity: 0};
  100% {opacity: 1};
}

@-o-keyframes fade {
  0% {opacity: 0};
  100% {opacity: 1};
}

@-webkit-keyframes fadeInOut {
0% {opacity: .2};
25% {opacity: 1};
0% {opacity: .2};
75% {opacity: 1};
0% {opacity: .2};
}

@-moz-keyframes fadeInOut{
  0% {opacity: .2};
  25% {opacity: 1};
  0% {opacity: .2};
  75% {opacity: 1};
  0% {opacity: .2};
}

@keyframes fadeInOut {
  0% {opacity: .2};
  25% {opacity: 1};
  0% {opacity: .2};
  75% {opacity: 1};
  0% {opacity: .2};
}

@-o-keyframes fadeInOut {
  0% {opacity: .2};
  25% {opacity: 1};
  0% {opacity: .2};
  75% {opacity: 1};
  0% {opacity: .2};
}

/*                   END GENERAL                  */


/*                   START TRACKS                  */
.trackSection {
  display: block;
}

.trackDiv {
  border: 1px solid #000000;
  width: 80%;
  margin: auto;
  overflow: auto;
}

.songImg {
  float: left;
  background: #000000;
  margin: 20px 20px 20px 20px;
}

.songInfoDiv {
  text-align: left;
  margin: 28px 0 0 0;
}

.songNameP {
  font-size: 14px;
}

.songArtistNameP {
  font-size: 12px;
}

.timeRangeTrackDiv {
  float: center;
}

.timeRangeTrack{
  display: inline-block;
  padding: 5px;
}

.timeRangeTrack:hover{
  display: inline-block;
  padding: 5px;
  background-color: #a2bbab;
}

.timeRangeTrackSelected{
  display: inline-block;
  padding: 5px;
  background-color: #a2bbab;
}

#topTrackPlaylistButton {
  margin: 20px;
  padding: 5px;
  background-color: #1ED760;
  display: inline-block;
  border-radius: 4px;
  color:#FFFFFF;
}
/*          a2bbab         END TRACKS   94B6A0               */




/*                   START ARTISTS                  */
.artistSection {
  display: none;
}

.artistDiv {
  border: 1px solid #000000;
  width: 80%;
  margin: auto;
  overflow: auto;
}

.artistImg {
  float: left;
  background: #000000;
  height:64px;
  width:64px;
  margin: 20px 20px 20px 20px;
}

.artistInfoDiv {
  text-align: left;
  margin: 28px 0 0 0;
}

.artistNameP {
  font-size: 20px;
}

.timeRangeArtistDiv {
  float: center;
}


.timeRangeArtist{
  display: inline-block;
  padding: 5px;
}

.timeRangeArtist:hover{
  display: inline-block;
  padding: 5px;
  background-color: #a2bbab;
}

.timeRangeArtistSelected{
  display: inline-block;
  padding: 5px;
  background-color: #a2bbab;
}

.numberArtistP{
  float: left;
  font-size: 20px;
  font-weight: 300;
  margin: 42px 0 0 10px;
}

/*                   END ARTISTS                  */

/*                   START STATS                  */
.statSection {
  display: none;
  margin: 0 auto;
}

.statTitle {
  text-align: left;
  font-size: 28px;
  font-weight: 700;
}

.statBlock {
  display: inline-block;
  width: 33%;
  padding-bottom: 20px;
}

.statHeader {
  color: #FFFFFF;
  font-size: 36px;
}

#energyStat {
  background-color: #68B15D;
  color: #FFFFFF;
}

#valenceStat {
  background-color: #DFBB50;
  color: #FFFFFF;
}

#tempoStat {
  background-color: #BC4A3E;
  color: #FFFFFF;
}
/*                   END STATS                  */

/* MOBILE */
@media screen and (max-width: 1000px) {

  /*                   START TRACKS                  */
  .trackDiv {
    border: 1px solid #000000;
    width: 100%;
    margin: auto;
    overflow: auto;
  }

  .songNameP {
    font-size: 12px;
  }

  .songArtistNameP {
    font-size: 10px;
  }

  /*                   END TRACKS                  */

  /*                   START ARTISTS                  */
  .artistDiv {
    border: 1px solid #000000;
    width: 100%;
    margin: auto;
    overflow: auto;
  }

  .artistNameP {
    font-size: 14px;
  }
   /*                   END ARTISTS                  */

   .statHeader {
     color: #FFFFFF;
     font-size: 20px;
   }

   .analysisMessage{
     font-size: 16px;
   }

   .analysisMessageChart{
     font-size: 16px;
   }
}
